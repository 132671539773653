define('ember-pell/components/pell-editor', ['exports', 'ember-pell/pell'], function (exports, _pell) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    pellOptions: null,

    onChange: function onChange() /*html*/{},


    valueObserver: Ember.observer('value', function () {
      if (this.get('pell')) {
        this._setValue();
      }
    }),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var pellInstance = _pell.default.init(this._options());

      this.set('pell', pellInstance.querySelector(".pell-content"));

      this._setValue();
    },
    _options: function _options() {
      return Object.assign({}, this.get('pellOptions'), {
        element: this.element,
        onChange: this.onChange
      });
    },
    _setValue: function _setValue() {
      if (this.get('pell').innerHTML !== this.get('value')) {
        this.get('pell').innerHTML = this.get('value');
      }
    }
  });
});